body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(216, 173, 245, 0.108);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* More */
.container {
  min-height: 100vh;
}

/* For the Home Pages Items */
#title {
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 26px;
  opacity: 0.8;
  color: rgb(166, 59, 201);
  /* color: rgba(15, 15, 15, 0.61); */
}
#expiry {
  font-size: 18px;
}

@media only screen and (max-width: 800px) {
  #title {
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 19px;
    opacity: 0.8;
    color: rgb(166, 59, 201);
    /* color: rgba(15, 15, 15, 0.61); */
  }
  #expiry {
    font-size: 16px;
  }
}
#llr {
  text-align: center;
  padding: 8px;
  margin-bottom: 8px;
  color: rgba(15, 15, 15, 0.61);
}

#home {
  display: grid;
  grid-template-areas:
    "one one two two three three"
    "one one two two three three";
  /* flex-wrap: nowrap;
  width: 100%;
  margin: auto; */
}

.home-contents {
  border: 1px solid rgba(137, 209, 235, 0.592);
  border-radius: 20px;
  background-color: azure;
  padding: 20px;
  min-height: 150px;
  margin: 6px;
  letter-spacing: 2px;
  text-align: center !important;
  font-weight: bold;
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0.1s;
}

.home-contents:hover {
  border: none;
  background-color: rgba(224, 220, 215, 0.445);
  text-decoration: none;
}

#card1 {
  grid-area: one;
}

#card2 {
  grid-area: two;
}

#card3 {
  grid-area: three;
}

#main-landing-page {
  /* opacity: 0.7; */
  height: 100vh;
  background-image: url("./images/main_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* BACKGROUND-POSITION IS SET IN LANDING.JS */
}

#register-main {
  background-color: rgba(207, 174, 235, 0.315);
  border-radius: 20px;
}

#register-form {
  width: 90%;
  margin: auto;
}

.modal-body label {
  color: dark;
  font-weight: bolder;
}

#llr-text {
  /* font-family: 'Roboto'; */
  color: white;
  /* -webkit-text-stroke: 1px black; */
  -webkit-text-stroke: 1px rgb(173, 83, 202);
  /* Creates a black outline around the text */
  -webkit-background-clip: text;
  /* Applies the background color to the text only */
  /* background: linear-gradient(to right, white, white); */
  /* Specifies the background color */
}

/* For students page pagination */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}
.activated {
  color: red;
  outline: none;
  box-shadow: none;
  background-color: #ddd;
  padding-right: 4px;
  padding-left: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

@keyframes swingLogo {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}
.logo-swing {
  /* Apply other styles if needed */
  animation: swingLogo 7s linear infinite;
  /* Change '3s' to the duration you want */
  /* transform-origin: y-axis, center; */
}

#loadingProgress {
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  height: 10px;
}

@media only screen and (max-width: 800px) {
  #home {
    display: grid;
    grid-template-areas:
      "one one one one one one"
      "two two two two two two"
      "three three three three three three";
  }
}

/* From App.css */
#footer {
  background-color: rgb(173, 83, 202);
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

/*For the buttons*/
#submit {
  background-color: rgb(173, 83, 202);
}

/*For the buttons when clicked*/
#buttonClicked {
  background-color: rgb(203, 50, 196);
}

/*Sets the background for the navbar*/
.navbar-expand-lg {
  background-color: rgb(173, 83, 202);
}

table.layout {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
}

table.layout tbody tr {
  background: #ececec;
  color: #000;
}

@media only screen and (max-width: 600px) {
  #newTeacherForm {
    display: grid;
    grid-template-areas:
      "code" "name" "phone"
      "sub";
    gap: 1em;
  }

  #mySelect {
    font-size: 12px;
    color: #4a4545;
  }

  table.layout thead tr td {
    color: #fff;
    font-size: 11px;
    padding: 5px 7px 5px;
  }

  table.layout tr td,
  th {
    padding: 5px 9px;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    font-size: 10px;
  }

  table.layout td span.btn {
    font-size: 11px;
  }
}

#mySelect {
  font-size: 14px;
  color: #000;
}

table.layout thead tr {
  background: linear-gradient(#fff, #eaeaea);
  font-weight: 600;
  color: #000;
}

table.layout thead tr td {
  background: #4de517 !important;
  border: 0;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 10px 8px;
}

table.layout tbody tr:nth-child(2n + 1) {
  background: #fff;
  color: #000;
  padding: 12px 20px;
}

table.layout tr td,
th {
  padding: 8px 12px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

table.layout td {
  padding: 8px;
  border: #f1f1f1 solid 1px;
}

/* .nav-link.active {
  border-bottom: 3px dotted white;
} */
.nav-link.active {
  text-decoration: underline;
  text-decoration-color: white;
  text-underline-offset: 3px;
}

.clicked {
  /* Clicked klass in Students.js */
  background-color: white;
  color: #000;
}

.link {
  /*This is for all Link objects*/
  text-decoration: none;
  color: rgb(183, 71, 221);
  font-weight: bold;
}

.link:hover {
  text-decoration: none;
}

table.layout td a i {
  margin-right: 2px;
}

table.layout td a:hover {
  color: #555;
}

table.layout td span.btn {
  font-size: 14px;
  border: 0;
}

#newTeacherForm > input {
  /* border: none; */
  background-color: #f5f0f0c9;
}

#newTeacherForm {
  display: grid;
  grid-template-areas: "code name name name phone phone sub";
  column-gap: 6px;
}

#code {
  grid-area: code;
}

#name {
  grid-area: name;
}

#phone {
  grid-area: phone;
}

#submit {
  grid-area: sub;
}

#searchTeacherForm,
#searchStudentForm {
  display: grid;
  grid-template-areas: "space space space space space space phone ";
}

#searchTeacherForm > input {
  grid-area: space;
}

#navbarNavDropdown {
  margin-right: 5%;
}

#searchTeacherForm > button,
#searchStudentForm > button {
  grid-area: phone;
}

.d-flex > li {
  padding-right: 30px;
  text-decoration: none;
}

/* Center loaders */
.loaders {
  top: 70%;
  left: 50%;
  transform: translate(-70%, -50%);
  position: absolute;
}

#login-form {
  width: 300px;
  top: 40%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

/* For the loading text#loading{
   */
#loading {
  justify-content: center;
  align-items: center;
}

/* FOR PAGINATION */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-container .pagination {
  display: inline-block;
}

.pagination-container .pagination li {
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.pagination-container .pagination li a {
  color: #333;
  text-decoration: none;
}

.pagination-container .pagination li.active {
  background-color: #007bff;
}

.pagination-container .pagination li.active a {
  color: #fff;
}

@font-face {
  font-family: "Play";
  src: url("../src/fonts/Play-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Chakra";
  src: url("../src/fonts/ChakraPetch-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: url("../src/fonts/LobsterTwo-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "NovaOval";
  src: url("../src/fonts/NovaOval-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Exo";
  src: url("../src/fonts/Exo-VariableFont_wght.ttf") format("truetype");
}

/* 
FOR THE SUBJECTS ADMIN CATEGORY TABLES */
/* CSS styles for the category titles and table */
.category-table {
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 18px;
  background-color: #f9f9f9;
}

.category-title {
  text-align: center;
  margin-bottom: 15px;
}

/* This ensures the component and the title are in one line */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
